
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
//import { reactI18nextModule } from "react-i18next"; 
import en from './en.json';
import es from './es.json';
import fr from './fr.json';
import pt from './pt.json';
const resources = {
  en: {
    translation: {
      ...en,
    }
  },
  es: {
    translation: {
      ...es,
    }
  },
  fr: {
    translation: {
      ...fr
    }
  },
  pt: {
    translation: {
      ...pt
    }
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;